import { gql } from 'graphql-request';
import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { FieldError, Label, SubmitButton, TextInput } from '@/components/dom/form-elements';
import { Bold, Heading, Paragraph } from '@/components/dom/text-elements';
import { useGlobalState } from '@/components/global/global-state';
import TOSMessaging from '@/components/global/tos-messaging';
import { GQL_CLIENT } from '@/lib/graphql';
import * as gtag from '@/lib/gtag';
import { CURRENT_USER_FIELDS, CurrentUserType } from '@/queries/current-user';

type LogInOrCreateFormProps = {
    signUpCopy?: React.ReactNode;
    logInCopy?: React.ReactNode;
    inviteId?: string;
    initialEmail?: string;
    onLoginSuccess?: (user: CurrentUserType) => void;
} & ({ openWith: 'log-in'; disableLogin?: never } | { openWith?: 'sign-up'; disableLogin?: boolean });

const LINK_BASE_STYLING = 'font-brand-bold !text-brand-blue-yves';

interface LoginFormProps {
    logInCopy?: React.ReactNode;
    showSignUpHandler: () => void;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
    onEmailInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    email: string;
    isSubmitting: boolean;
    emailError: string;
}

const LoginForm = ({
    logInCopy,
    showSignUpHandler,
    onSubmit,
    onEmailInput,
    email,
    isSubmitting,
    emailError,
}: LoginFormProps) => {
    return (
        <div>
            <div className="mb-6">
                {logInCopy ? (
                    logInCopy
                ) : (
                    <Paragraph className="font-brand-md">
                        Please enter the email address you used to sign up for Thematic. We&apos;ll email you a
                        one&#45;time passcode to log in.
                    </Paragraph>
                )}
                <Paragraph>
                    {`Don't have an account yet? `}
                    <a
                        onClick={showSignUpHandler}
                        className={LINK_BASE_STYLING}
                    >
                        Create one
                    </a>
                </Paragraph>
            </div>
            <hr className="mb-6" />
            <form
                onSubmit={onSubmit}
                noValidate
            >
                <div className="mb-6">
                    <Label
                        text="Email"
                        labelCopyClassName="text-left"
                    >
                        <TextInput
                            className="w-full"
                            value={email}
                            onChange={onEmailInput}
                            required={true}
                            spellCheck={false}
                            placeholder="someonecool@yourorganization.com"
                            autoFocus={true}
                        />
                    </Label>
                    <FieldError message={emailError} />
                </div>
                <SubmitButton
                    isDisabled={isSubmitting}
                    value="Send one-time passcode"
                    roundedCorners="full"
                    className="font-brand-md"
                    color="primary-gradient"
                />
            </form>
        </div>
    );
};

interface OTPFormProps {
    resendOtpAction: () => void;
    showLoginViewAction: (category: string) => void;
    submitOtpForm: (event: FormEvent<HTMLFormElement>) => void;
    email: string;
    otp: string;
    onOTPChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    otpError: string;
    isSubmitting: boolean;
    isOtpAfterSignup: boolean;
}

const OTPForm = ({
    resendOtpAction,
    showLoginViewAction,
    submitOtpForm,
    email,
    otp,
    onOTPChange,
    otpError,
    isSubmitting,
    isOtpAfterSignup,
}: OTPFormProps) => {
    return (
        <div>
            <div className="text-center mb-6">
                <Heading
                    importance={3}
                    className="mb-4"
                >
                    Your one-time passcode was sent
                </Heading>
                <Paragraph>
                    Check your email (<Bold>{email.toLowerCase()}</Bold>) for your one-time passcode. The passcode
                    expires in 15 minutes. If you didn’t receive it, please verify your email address and check your
                    spam folder.{' '}
                </Paragraph>
                <Paragraph size="sm">
                    Problems?{' '}
                    <a
                        onClick={resendOtpAction}
                        className={LINK_BASE_STYLING}
                    >
                        Resend passcode
                    </a>{' '}
                    or{' '}
                    <a
                        onClick={() => showLoginViewAction('OTP')}
                        className={LINK_BASE_STYLING}
                    >
                        Re-enter your email
                    </a>
                    .
                </Paragraph>
            </div>
            <form onSubmit={submitOtpForm}>
                <div className="mb-6">
                    <Label
                        text="One-time passcode"
                        labelCopyClassName="text-left"
                    >
                        <TextInput
                            className="w-full"
                            value={otp}
                            onChange={onOTPChange}
                            required={true}
                            spellCheck={false}
                            placeholder="ex. 123456"
                        />
                    </Label>
                    <FieldError message={otpError} />
                </div>
                <SubmitButton
                    isDisabled={isSubmitting}
                    value={isOtpAfterSignup ? 'Sign up' : 'Log in'}
                    roundedCorners="md"
                />
            </form>
        </div>
    );
};

interface SignupFormProps {
    signUpCopy?: React.ReactNode;
    disableLogin?: boolean;
    showLoginViewAction: (category: string) => void;
    submitSignUpForm: (event: FormEvent<HTMLFormElement>) => void;
    onEmailInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    email: string;
    emailError: string;
    onNameInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    nameError: string;
    isSubmitting: boolean;
}

const SignupForm = ({
    signUpCopy,
    disableLogin,
    showLoginViewAction,
    submitSignUpForm,
    onEmailInput,
    email,
    emailError,
    onNameInput,
    name,
    nameError,
    isSubmitting,
}: SignupFormProps) => {
    return (
        <div>
            <div className="mb-6">
                {signUpCopy ? (
                    signUpCopy
                ) : (
                    <Paragraph>
                        Enter your details below to create a Thematic account. We&apos;ll email you a one&#45;time
                        passcode to sign in &ndash; no passwords to remember!
                    </Paragraph>
                )}
                {!disableLogin && (
                    <Paragraph>
                        Already have an account? {` `}
                        <a
                            onClick={() => showLoginViewAction('Sign Up')}
                            className={LINK_BASE_STYLING}
                        >
                            Log in
                        </a>
                    </Paragraph>
                )}
            </div>
            <hr className="mb-6" />
            <form
                onSubmit={submitSignUpForm}
                noValidate
            >
                <div className="mb-6">
                    <Label
                        text="Email"
                        labelCopyClassName="text-left"
                    >
                        <TextInput
                            placeholder=""
                            className="w-full"
                            value={email}
                            onChange={onEmailInput}
                            required={true}
                            spellCheck={false}
                            testId="sign-up-email"
                            autoFocus={true}
                        />
                    </Label>
                    <FieldError message={emailError} />
                </div>
                <div className="mb-6">
                    <Label
                        text="Name"
                        labelCopyClassName="text-left"
                    >
                        <TextInput
                            placeholder="First and last name"
                            className="w-full"
                            value={name}
                            onChange={onNameInput}
                            required={true}
                            spellCheck={false}
                            testId="sign-up-name"
                        />
                    </Label>
                    <FieldError message={nameError} />
                </div>
                <TOSMessaging clickedString="Send one-time passcode" />
                <SubmitButton
                    isDisabled={isSubmitting}
                    value="Send one-time passcode"
                    roundedCorners="md"
                />
            </form>
        </div>
    );
};

const LogInOrCreateForm = ({
    signUpCopy,
    logInCopy,
    openWith = 'sign-up',
    inviteId,
    initialEmail,
    disableLogin,
    onLoginSuccess,
}: LogInOrCreateFormProps) => {
    const { setGlobalState } = useGlobalState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showLogIn, setShowLogIn] = useState(openWith === 'log-in');
    const [showOtpEntry, setShowOtpEntry] = useState(false);
    const [email, setEmail] = useState(initialEmail || '');
    const [emailError, setEmailError] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [loginAttemptId, setLoginAttemptId] = useState('');
    const [isOtpAfterSignup, setIsOtpAfterSignup] = useState(true);
    const showSignUpView = () => {
        setShowLogIn(false);
        setShowOtpEntry(false);
        gtag.event({
            action: 'Switch to Sign Up',
            category: 'Log In',
            label: 'Create one',
        });
    };
    const showLogInView = (category: string) => {
        setShowLogIn(true);
        setShowOtpEntry(false);
        gtag.event({
            action: 'Switch to Log In',
            category,
            label: 'Log in',
        });
    };
    const onEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError('');
    };
    const onNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError('');
    };
    const onOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtp(event.target.value);
        setOtpError('');
    };

    const resendOtp = async () => {
        setOtp('');
        setOtpError('');
        const variables = { loginAttemptId };

        const query = gql`
            mutation ResendLoginAttempt($loginAttemptId: ID!) {
                resendLoginAttempt(input: { loginAttemptId: $loginAttemptId }) {
                    __typename
                    ... on SendLoginAttemptSuccess {
                        loginAttemptId
                    }
                    ... on Error {
                        message
                    }
                }
            }
        `;

        const data: {
            resendLoginAttempt:
                | { __typename: 'SendLoginAttemptSuccess'; loginAttemptId: string }
                | { __typename: 'Error'; message: string };
        } = await GQL_CLIENT.request(query, variables);
        const result = data.resendLoginAttempt;
        const success = result.__typename === 'SendLoginAttemptSuccess';

        if (success) {
            toast.success('Your one-time passcode was resent!');
        } else {
            toast.error(result.message);
        }

        gtag.event({
            action: success ? 'Success' : 'Error',
            category: 'Log In/Up',
            label: 'Resend OTP',
        });
    };

    const submitOtpForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        const variables = {
            challengeToken: otp,
            loginAttemptId,
        };

        const query = gql`
            mutation CreateUserSession($loginAttemptId: String!, $challengeToken: String!) {
                createUserSession(input: { loginAttemptId: $loginAttemptId, challengeToken: $challengeToken }) {
                    __typename
                    ... on UserSession {
                        user {
                            ${CURRENT_USER_FIELDS}
                        }
                        sessionToken
                        sessionExpiresAt
                    }
                    ... on Error {
                        message
                    }
                }
            }
        `;

        const data: {
            createUserSession:
                | {
                      __typename: 'UserSession';
                      user: CurrentUserType;
                      sessionToken: string;
                      sessionExpiresAt: string;
                  }
                | {
                      __typename: 'Error';
                      message: string;
                  };
        } = await GQL_CLIENT.request(query, variables);

        const result = data.createUserSession;
        const success = result.__typename === 'UserSession';

        if (success) {
            setGlobalState((prev) => {
                return {
                    ...prev,
                    currentUser: result.user,
                };
            });
            toast.success('You’re now signed in.');

            if (onLoginSuccess) onLoginSuccess(result.user);
        } else {
            setOtpError(result.message);
        }
        setIsSubmitting(false);
        gtag.event({
            action: success ? 'Success' : 'Error',
            category: 'Log In/Up',
            label: 'Submit OTP',
        });
    };

    const submitLogInForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        const variables = {
            email,
        };

        const query = gql`
            mutation CreateLoginAttempt($email: String!) {
                createLoginAttempt(input: { email: $email }) {
                    __typename
                    ... on SendLoginAttemptSuccess {
                        loginAttemptId
                    }
                    ... on Error {
                        message
                    }
                }
            }
        `;

        const data: {
            createLoginAttempt:
                | {
                      __typename: 'SendLoginAttemptSuccess';
                      loginAttemptId: Optional<string>;
                  }
                | {
                      __typename: 'Error';

                      message: string;
                  };
        } = await GQL_CLIENT.request(query, variables);
        const result = data.createLoginAttempt;
        const success = result.__typename === 'SendLoginAttemptSuccess';

        if (success) {
            setLoginAttemptId(result.loginAttemptId || '');
            setIsOtpAfterSignup(false);
            setShowOtpEntry(true);
        } else {
            setEmailError(result.message);
        }
        setIsSubmitting(false);

        gtag.event({
            action: success ? 'Success' : 'Error',
            category: 'Log In',
            label: 'Submit',
        });
    };

    const submitSignUpForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        const variables = { email, inviteId, name };

        const query = gql`
            mutation createUserAndOrganization($email: String!, $name: String!, $inviteId: ID) {
                createUserAndOrganization(input: { email: $email, name: $name, inviteId: $inviteId }) {
                    __typename
                    ... on CreateUserSuccess {
                        userId
                        loginAttemptId
                    }
                    ... on FieldErrors {
                        errors {
                            field
                            message
                        }
                    }
                }
            }
        `;

        const data: {
            createUserAndOrganization:
                | {
                      __typename: 'FieldErrors';
                      errors: Array<{
                          field: string;
                          message: string;
                      }>;
                  }
                | {
                      __typename: 'CreateUserSuccess';
                      userId: Optional<string>;
                      loginAttemptId: Optional<string>;
                  };
        } = await GQL_CLIENT.request(query, variables);
        const result = data.createUserAndOrganization;

        const success = result.__typename === 'CreateUserSuccess';

        if (success) {
            setLoginAttemptId(result.loginAttemptId || '');
            setIsOtpAfterSignup(true);
            setShowOtpEntry(true);
        } else {
            result.errors.forEach((value: { field: string; message: string }) => {
                if (value.field === 'email_address') {
                    setEmailError(value.message);
                } else if (value.field === 'name') {
                    setNameError(value.message);
                } else {
                    toast.error(value.message);
                }
            });
        }

        gtag.event({
            action: success ? 'Success' : 'Error',
            category: 'Sign Up',
            label: 'Submit',
        });

        setIsSubmitting(false);
    };

    let content = null;

    if (showOtpEntry) {
        content = (
            <OTPForm
                resendOtpAction={resendOtp}
                showLoginViewAction={showLogInView}
                submitOtpForm={submitOtpForm}
                email={email}
                otp={otp}
                onOTPChange={onOTPChange}
                otpError={otpError}
                isSubmitting={isSubmitting}
                isOtpAfterSignup={isOtpAfterSignup}
            />
        );
    } else if (showLogIn) {
        content = (
            <LoginForm
                logInCopy={logInCopy}
                showSignUpHandler={showSignUpView}
                onSubmit={submitLogInForm}
                email={email}
                onEmailInput={onEmailInput}
                emailError={emailError}
                isSubmitting={isSubmitting}
            />
        );
    } else {
        content = (
            <SignupForm
                signUpCopy={signUpCopy}
                disableLogin={disableLogin}
                showLoginViewAction={showLogInView}
                submitSignUpForm={submitSignUpForm}
                onEmailInput={onEmailInput}
                email={email}
                emailError={emailError}
                onNameInput={onNameInput}
                name={name}
                nameError={nameError}
                isSubmitting={isSubmitting}
            />
        );
    }

    return <div className="mt-8 mx-auto text-center">{content}</div>;
};
export default LogInOrCreateForm;
